body {
  font-family: "Titillium Web", sans-serif;
  font-size: 1.2em;
}

main {
  min-height: calc(100vh - 135px);
}

.content {
  padding: 15px;
}

header,
main,
footer {
  padding-left: 0px;
}

.canvas-container {
  height: 60vh;
}

@media only screen and (max-width: 992px) {
  header,
  main,
  footer {
    padding-left: 0;
  }

  main {
    min-height: calc(100vh - 135px);
  }

  .canvas-container {
    height: 60vh;
  }
}
